import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import OpenRolesMain from "../components/careers/open-roles-main"
import CareerCtas from "../components/careers/career-ctas"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
      acfOptionsCareers {
        cfCareersSingleVac {
          singleVacHeroImage1 {
            altText
            mediaDetails {
              file
            }
          }
        }
      }
      vacancies(where: { status: PUBLISH }) {
        nodes {
          uri
          title
          id
          cfCareersOpenRoles {
            openContractType
            openHideBenefitsSection
            openHideFaqsSectionCopy
            openHideRightToWorkInfo
            openIntroText
            openLocation
            openSalary
            openVacancyId
            roleFullDescription
            openDesireable {
              openDesireableBullet
            }
            openRequirements {
              openRequirementBullet
            }
          }
        }
      }
    }
  }
`
const PageTemplateCareersOpenRoles = ({ data }) => {
  const page = data.wpgraphql.page
  const vacancy = data.wpgraphql.vacancies.nodes
  const previewImg =
    data.wpgraphql.acfOptionsCareers.cfCareersSingleVac.singleVacHeroImage1
      .mediaDetails.file

  return (
    <>
      <Layout>
        <OpenRolesMain pagedata={page} vacancies={vacancy} />
        <CareerCtas previewImg={previewImg} />
      </Layout>
    </>
  )
}

export default PageTemplateCareersOpenRoles

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
