import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import TalentPoolModal from "./talent-pool-modal"
import MailChimpModal from "./mailchimp-modal"

import * as style from "./career-ctas.module.css"

const CareerCtas = ({ previewImg }) => {
  const [showTalentPool, setShowTalentPool] = useState(false)

  const handleCloseTalentPool = () => setShowTalentPool(false)
  const handleShowTalentPool = () => setShowTalentPool(true)

  const callbackModalTalentPool = () => {
    setShowTalentPool(false)
  }

  const [showMailChimp, setShowMailChimp] = useState(false)

  const handleCloseMailChimp = () => setShowMailChimp(false)
  const handleShowMailChimp = () => setShowMailChimp(true)

  const callbackModal = () => {
    setShowMailChimp(false)
  }

  return (
    <>
      <div className="container mb-6">
        <div className="row">
          <div className="col-12 col-lg-6 mb-3 mb-lg-0">
            <div className="d-flex flex-wrap light_bg h-100">
              <div className="col-12 col-sm-8 p-4 d-flex flex-column justify-content-center align-items-start">
                <h3 className="mb-3">Join our talent pool</h3>
                <p>
                  We always want to hear from talented people, join our talent
                  pool and we'll contact you when a new opportunity becomes
                  available.
                </p>
                <span
                  className="ani_grey_arrow white-circle mt-4"
                  title="Join Talent Pool"
                  aria-label="Join Talent Pool"
                  role="link"
                  tabIndex={0}
                  onClick={handleShowTalentPool}
                >
                  Join Talent Pool
                </span>
              </div>
              <div className="col-12 col-sm-4 d-flex align-items-end justify-content-end">
                <img
                  className={`w-100 pt-0 pt-md-3 ${style.max_width}`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/v1690287949/jill-headshot_wpel1n.jpg"
                  alt="a waracle employee"
                  style={{ marginRight: "5px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex flex-wrap light_bg h-100">
              <div className="col-12 col-sm-8 p-4 d-flex flex-column justify-content-center align-items-start">
                <h3 className="mb-3">Recruitment Updates</h3>
                <p>
                  Want to be the first to hear when a new role is open?
                  Subscribe for our recruitment updates and we will send these
                  straight to your inbox.
                </p>
                <span
                  className="ani_grey_arrow white-circle mt-4"
                  title="Subscribe"
                  aria-label="Subscribe"
                  role="link"
                  tabIndex={0}
                  onClick={handleShowMailChimp}
                >
                  Subscribe
                </span>
              </div>
              <div className="col-12 col-sm-4 d-flex align-items-end justify-content-end">
                <img
                  className={`w-100 pt-0 pt-md-3 ${style.max_width}`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/waracle-notification_a2cg1r.png"
                  alt="waracle notification"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*// Talent pool modal */}

      <Modal
        show={showTalentPool}
        onHide={handleCloseTalentPool}
        fullscreen={true}
        scrollable={true}
      >
        <TalentPoolModal
          close={callbackModalTalentPool}
          righttowork={true}
          recruiteeid="Speculative Applications"
          position="Waracle Talent Pool"
          location="Remote"
          contract="London, Edinburgh, Glasgow, Dundee"
          showPreview={true}
          previewImg={previewImg}
          closemsg="Back to Careers page"
        />
      </Modal>

      {/*// Newsletter modal*/}

      <Modal show={showMailChimp} onHide={handleCloseMailChimp} size="md">
        <MailChimpModal close={callbackModal} />
      </Modal>
    </>
  )
}

export default CareerCtas
